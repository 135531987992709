import React, { useRef, useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { motion, useViewportScroll } from 'framer-motion'

const FullWidthPost = ({
  imageSource,
  date,
  title,
  description,
  postUrl,
  alignRight = false,
  scrollAnimate = true,
}) => {
  const { scrollY } = useViewportScroll()
  const [currentScrollY, setCurrentScrollY] = useState(0)

  const ref = useRef()

  useEffect(() => {
    if (!ref.current) return

    return scrollY.onChange((v) => {
      setCurrentScrollY(v)
    })
  }, [ref, scrollY])

  const titleVisible = useMemo(() => {
    if (!ref.current || currentScrollY <= 10) return
    const boundingRect = ref.current.getBoundingClientRect()
    const elementScrollStart = boundingRect.top / window.innerHeight
    return elementScrollStart < 0.65 && boundingRect.bottom > 0
  }, [currentScrollY])

  let contentStyle = {}
  //backgroundImage: `linear-gradient( rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.7) ), url(${imageSource})`,
  let imageStyle = {
    backgroundImage: `url(${imageSource})`,
    backgroundSize: '100% auto',
    width: 'calc(100vw + 100px)',
  }
  let imageInitialStyle = {}
  let animateStyle = {
    opacity: titleVisible ? 1 : 0,
  }
  if (alignRight) {
    contentStyle.alignItems = 'flex-end'
    imageInitialStyle.right = '-100'
    animateStyle.marginRight = titleVisible ? 0 : -75
  } else {
    contentStyle.left = 'flex-start'
    imageInitialStyle.left = '-100'
    animateStyle.marginLeft = titleVisible ? 0 : -75
  }
  let imageAnimateStyle = {}

  const scrollPositionBackground = useMemo(() => {
    if (currentScrollY <= 10) return

    const boundingRect = ref.current.getBoundingClientRect()
    const interpolateScroll = boundingRect.bottom / window.innerHeight

    return Math.min(Math.max(parseInt(-100 * interpolateScroll), -100), 0)
  }, [currentScrollY])

  if (alignRight && scrollAnimate) {
    imageAnimateStyle.right = scrollPositionBackground
  } else if (scrollAnimate) {
    imageAnimateStyle.left = scrollPositionBackground
  }

  return (
    <motion.article className="post-roll__post" ref={ref}>
      <Link to={postUrl}>
        <motion.div className="post-roll__content" style={contentStyle}>
          <motion.div
            initial={{ opacity: 0, marginLeft: 0, marginRight: 0 }}
            animate={animateStyle}
            transition={{ delay: 0.25, duration: 1 }}
            className="post-roll__subtitle"
          >
            {date}
          </motion.div>
          <motion.div
            initial={{ opacity: 0, marginLeft: 0, marginRight: 0 }}
            animate={animateStyle}
            transition={{ delay: 0.25, duration: 1 }}
            className="post-roll__title"
          >
            {title}
          </motion.div>
          <motion.div
            initial={{ opacity: 0, marginLeft: 0, marginRight: 0 }}
            animate={animateStyle}
            transition={{ delay: 0.25, duration: 1 }}
            className="post-roll__description"
          >
            {description}
          </motion.div>
          <motion.div
            initial={{ opacity: 0, marginLeft: 0, marginRight: 0 }}
            animate={animateStyle}
            transition={{ delay: 0.25, duration: 1 }}
            className="post-roll__read-more">
            Read More >
          </motion.div>
        </motion.div>
        <motion.div
          className="post-roll__post-img"
          initial={imageInitialStyle}
          animate={imageAnimateStyle}
          style={imageStyle}
        />
      </Link>
    </motion.article>
  )
}

FullWidthPost.propTypes = {
  imageSource: PropTypes.string,
  date: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  onClick: PropTypes.func,
  alignRight: PropTypes.bool,
  postUrl: PropTypes.string,
}

export default FullWidthPost
