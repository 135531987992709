import * as THREE from 'three'
import React, { Suspense, useRef, useEffect } from 'react'
import { Canvas, useFrame, useLoader } from 'react-three-fiber'

const Cloud = (props) => {
  const mesh = useRef()
  const rotationalSpeed = Math.random() * 0.002 - 0.001
  const position = [
    Math.random() * 800 - 400,
    Math.random() * 10 - 5,
    Math.random() * 200 - 250,
  ]
  const rotation = [0, 0, Math.random() * 2 * Math.PI]
  useFrame(() => {
    mesh.current.rotation.z -= rotationalSpeed
  })

  useEffect(() => {})

  const texture = useLoader(THREE.TextureLoader, props.textureFile)

  return (
    <mesh {...props} ref={mesh} position={position} rotation={rotation}>
      <planeBufferGeometry args={[400, 400, 1, 1]} />
      <meshLambertMaterial
        blending={THREE.AdditiveBlending}
        blendEquation={THREE.AddEquation}
        blendSrc={THREE.SrcAlphaFactor}
        blendDst={THREE.SrcColorFactor}
        attach="material"
        map={texture}
        depthTest={false}
        transparent={true}
        opacity={1}
      />
    </mesh>
  )
}

const FeaturedClouds = (props) => {
  const textures = [
    '/img/cloud_1.png',
    '/img/cloud_2.png',
    '/img/cloud_3.png',
    '/img/cloud_4.png',
  ]
  const clouds = Array(26)
    .fill()
    .map(() => {
      const texture = Math.floor(Math.random() * 4)
      return textures[texture]
    })
  return (
    <Canvas
      style={{ zIndex: 2 }}
      camera={{ fov: 75, position: [0, 0, 200] }}
      resize={{ scroll: false }}
    >
      <pointLight position={[0, 0, -20]} intensity={12} color="#168EFF" />
      <pointLight position={[0, 0, -80]} intensity={12} color="#FF42A9" />
      <pointLight position={[0, 0, -150]} intensity={20} color="#E800F0" />
      <Suspense fallback={null}>
        {clouds.map((cloud, i) => {
          return <Cloud textureFile={cloud} key={i} />
        })}
      </Suspense>
    </Canvas>
  )
}

export default FeaturedClouds
