import React from 'react'
import { motion } from 'framer-motion'
import FeaturedClouds from './FeaturedClouds'

import './Featured.scss'

const Featured = ({
  title,
  subheading,
  image,
  videoSourceURL,
  videoTitle,
  featuredUrl,
}) => {
  return (
    <motion.div
      className="featured"
      style={{
        position: 'relative',
        width: '100%',
        height: '70vh',
        overflow: 'hidden',
      }}
    >
      <FeaturedClouds />
      <motion.div className="featured__card">
        <motion.div className="featured__image--overlay">
          <motion.img src="/img/bokeh_hexagon_overlay_black.svg" />
        </motion.div>
        <motion.img src={image.publicURL} className="featured__image" />
      </motion.div>
      <motion.div className="featured__date">{subheading}</motion.div>
      <motion.div className="featured__tag">Featured</motion.div>
      {/* <motion.div className="featured__title--underlay">{title}</motion.div> */}
      <a href={featuredUrl} className="featured__title">
        <motion.div>
          {title}
        </motion.div>
        <motion.div className="featured__readmore">
          Read More >
        </motion.div>
      </a>
    </motion.div>
  )
}

export default Featured
