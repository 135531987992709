import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import Featured from '../featured/Featured'
import { navigate } from 'gatsby'
import FullWidthPost from './FullWidthPost'
//import { isMobile } from 'react-device-detect'

import './FullPagePostRoll.scss'

const FullPagePostRoll = ({ data }) => {
  const { edges: posts } = data.allMarkdownRemark
  const ref = useRef()

  const featuredPost =
    posts &&
    posts.filter(({ node: post }) => {
      return !!post.frontmatter.featuredpost
    })

  let featuredNode = null

  if (featuredPost && featuredPost.length > 0) {
    featuredNode = featuredPost[0].node
  }

  const normalPosts =
    posts &&
    posts.filter(({ node: post }) => {
      return !post.frontmatter.featuredpost
    })

  return (
    <div className="post-roll" ref={ref}>
      {featuredNode && (
        <Featured
          title={featuredNode.frontmatter.title}
          subheading={featuredNode.frontmatter.date}
          image={featuredNode.frontmatter.featuredimage}
          featuredUrl={featuredNode.fields.slug}
          videoSourceURL={featuredNode.frontmatter.videoSourceURL}
          videoTitle={featuredNode.frontmatter.videoTitle}
        />
      )}
      <div className="post-roll__container">
        {normalPosts &&
          normalPosts.map(({ node: post }, index) => {
            return (
              <FullWidthPost
                key={post.id}
                imageSource={post.frontmatter.featuredimage?.publicURL}
                date={post.frontmatter.date}
                title={post.frontmatter.title}
                description={post.frontmatter.description}
                alignRight={index % 2 === 1}
                postUrl={post.fields.slug}
                scrollAnimate={false}
              />
            )
          })}
      </div>
    </div>

    // <div className="columns is-multiline">
    //   {posts &&
    //     posts.map(({ node: post }) => (
    //       <div className="is-parent column is-6" key={post.id}>
    //         <article
    //           className={`blog-list-item tile is-child box notification ${
    //             post.frontmatter.featuredpost ? 'is-featured' : ''
    //           }`}
    //         >
    //           <header>
    //             {post.frontmatter.featuredimage ? (
    //               <div className="featured-thumbnail">
    //                 <PreviewCompatibleImage
    //                   imageInfo={{
    //                     image: post.frontmatter.featuredimage,
    //                     alt: `featured image thumbnail for post ${post.frontmatter.title}`,
    //                   }}
    //                 />
    //               </div>
    //             ) : null}
    //             <p className="post-meta">
    //               <Link
    //                 className="title has-text-primary is-size-4"
    //                 to={post.fields.slug}
    //               >
    //                 {post.frontmatter.title}
    //               </Link>
    //               <span> &bull; </span>
    //               <span className="subtitle is-size-5 is-block">
    //                 {post.frontmatter.date}
    //               </span>
    //             </p>
    //           </header>
    //           <p>
    //             {post.excerpt}
    //             <br />
    //             <br />
    //             <Link className="button" to={post.fields.slug}>
    //               Keep Reading →
    //             </Link>
    //           </p>
    //         </article>
    //       </div>
    //     ))}
    // </div>
  )
}

FullPagePostRoll.propTypes = {
  data: PropTypes.shape({
    allMdx: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default FullPagePostRoll
